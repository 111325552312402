<template>
    <v-form ref="form" @submit.prevent="submit()" class="mt-4">
        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field 
                    v-model="form.nombre" 
                    label="Nombre" 
                    outlined
                    :error-messages="nombreErrors"
                    @change="$v.form.nombre.$touch()"
                    @blur="$v.form.nombre.$touch()"
                    class="mt-2"
                />
                <v-text-field 
                    v-model="form.numero" 
                    label="Numero" 
                    outlined
                    :error-messages="numeroErrors"
                    @change="$v.form.numero.$touch()"
                    @blur="$v.form.numero.$touch()"
                    class="mt-2"
                />
                <v-textarea
                    v-model="form.descripcion"
                    label="Descripción"
                    outlined
                    :error-messages="descripcionErrors"
                    @change="$v.form.descripcion.$touch()"
                    @blur="$v.form.descripcion.$touch()"
                    no-resize
                    class="mt-2"
                ></v-textarea>
                <v-file-input
                    v-if="!product"
                    v-model="form.foto"
                    label="Foto"
                    :error-messages="fotoErrors"
                    @blur="$v.form.foto.$touch()"
                    accept="image/*"
                    outlined
                    class="mt-2"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-select
                    v-model="form.id_tipo_producto"
                    :items="productTypes.data"
                    :loading="productTypes.isLoading"
                    label="Tipo de producto"
                    outlined
                    item-text="nombre"
                    item-value="id"
                    :error-messages="id_tipo_productoErrors"
                    @change="$v.form.id_tipo_producto.$touch()"
                    @blur="$v.form.id_tipo_producto.$touch()"
                    class="mt-2"
                />
                <v-select
                    :items="families.data"
                    :loading="families.isLoading"
                    v-model="extraFormFields.id_familia"
                    label="Familia"
                    outlined
                    item-text="nombre"
                    item-value="id"
                    @change="handleFamilyChange"
                    class="mt-2"
                />
                <v-select
                    :items="groups.data"
                    :loading="groups.isLoading"
                    v-model="extraFormFields.id_grupo"
                    label="Grupo"
                    :disabled="!extraFormFields.id_familia"
                    outlined
                    item-text="nombre"
                    item-value="id"
                    @change="handleGroupChange"
                    class="mt-2"
                />
                <ProgresiveLoadAutocomplete
                    v-model="form.id_subgrupo"
                    placeholder="Seleccione un subgrupo"
                    label="Subgrupo"
                    outlined
                    :disabled="!extraFormFields.id_grupo || atributosProducto.isLoading"
                    :items="subGrupo_list.data"
                    :loading="subGrupo_list.isLoading"
                    :pageable="subGrupo_list" 
                    item-text="nombre"
                    item-value="id"
                    :error-messages="id_subgrupoErrors"
                    @change="$v.form.id_subgrupo.$touch()"
                    @blur="$v.form.id_subgrupo.$touch()"
                    @search="buscarSubGrupo"
                    @load-more="cargarMasSubGrupo"
                    class="mt-2"
                />
                <v-autocomplete 
                    v-model="form.atributosProducto"
                    :loading="atributosProducto.isLoading" 
                    multiple 
                    chips
                    outlined 
                    label="Seleccione los atributos" 
                    :disabled="!extraFormFields.id_grupo"
                    deletable-chips 
                    :items="atributosProducto.data" 
                    item-text="CMAtributo.nombre"
                    item-value="id"
                />
            </v-col>
        </v-row>
        <div class="d-flex justify-end mt-4">
            <v-btn class="flex-grow-1 flex-shrink-1" @click.stop="close" color="secondary">Cerrar</v-btn>
            <v-btn class="flex-grow-1 flex-shrink-1 ml-4" type="submit" color="primary">
                {{ editting ? "Siguiente" : "Finalizar" }} 
            </v-btn>
        </div>
    </v-form>
</template>
<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation.js';
import { validationMixin } from 'vuelidate';
import { 
    createLoadable, 
    createPageable,
    togglePageable,
    setPageableResponse,
    toggleLoadable,
    setLoadableResponse,
    isResponseSuccesful,
    shouldFetch,
} from '@/utils/loadable.js';
import { ProgresiveLoadAutocomplete } from '@/components/utils';

export default {
    name: 'GeneralidadesForm',
    mixins: [validationMixin],
    props: {
        onClose: { type: Function, },
        product: { type: Object, },
        isParentModalOpen: { type: Boolean },
        editting: { type: Boolean },
    },
    emits: ['moveNextStep', 'finish'],
    components: { ProgresiveLoadAutocomplete },
    validations() {
        return {
            form: {
                nombre: { required, maxLength: maxLength(200), },
                descripcion: { required, maxLength: maxLength(350), },
                numero: { required, maxLength: maxLength(200), },
                id_tipo_producto: { required, },
                id_subgrupo: { required, },
                ...(!this.$props.product && { foto: {} }),
            },
        };
    },
    data: () => ({
        filtroSubGrupo: '',
        families: createLoadable([]),
        groups: createLoadable([]),
        subgroups: createLoadable([]),
        atributosProducto: createLoadable([]),
        productTypes: createLoadable([]),
        subGrupo_list: createPageable([], 30),
        extraFormFields: {
            id_familia: null,
            id_grupo: null,
        },
        form: {
            nombre: null,
            descripcion: null,
            numero: null,
            id_tipo_producto: null,
            id_subgrupo: null,
            foto: null,
            atributosProducto: [],
        },
        paginacionSubGrupo: {
            pagina: 1,
            registrosPorPagina: 10,
        },
    }),
    computed: {
        nombreErrors() {
            return new Validator(this.$v.form.nombre).detect().getResult();
        },
        fotoErrors() {
            return new Validator(this.$v.form.foto).detect().getResult();
        },
        descripcionErrors() {
            return new Validator(this.$v.form.descripcion).detect().getResult();
        },
        numeroErrors() {
            return new Validator(this.$v.form.numero).detect().getResult();
        },
        id_tipo_productoErrors() {
            return new Validator(this.$v.form.id_tipo_producto).detect().getResult();
        },
        id_subgrupoErrors() {
            return new Validator(this.$v.form.id_subgrupo).detect().getResult();
        },
        idSubGrupo() {
            return this.form.id_subgrupo;
        },
    },
    methods: {
        async fetchProductTypes() {
            toggleLoadable(this.productTypes);
            const { data } = await this.services.Product.getProductTypes();
            setLoadableResponse(this.productTypes, data);
        },
        async fetchFamilies() {
            toggleLoadable(this.families);
            const { data } = await this.services.CmFamilias.obtenerFamilias({ pagination: false });
            setLoadableResponse(this.families, data);
        },
        async fetchGroups(familyId) {
            toggleLoadable(this.groups);
            const { data } = await this.services.Group.getGroups({ id_familia: familyId, pagination: false });
            setLoadableResponse(this.groups, data);
        },
        async fetchSubProcesoAtributoProducto(idSubGrupo) {
            toggleLoadable(this.atributosProducto);
            const { data } = await this.services.cmProducto.fetchSubProcesoAtributoProducto(idSubGrupo);
            setLoadableResponse(this.atributosProducto, data);
        },
        async handleFamilyChange(value) {
            this.extraFormFields.id_familia = value;
            this.extraFormFields.id_grupo = null;
            this.form.id_subgrupo = null;
            this.$v.form.id_subgrupo.$reset();
            if (!value)
                return;
            await this.fetchGroups(value);
        },
        handleGroupChange(value) {
            this.extraFormFields.id_grupo = value;
            this.form.id_subgrupo = null;
            this.$v.form.id_subgrupo.$reset();
            if (!value) return;

            this.obtenerSubGrupo(value);
        },
        // UI
        clearForm() {
            this.$v.$reset();
            this.$refs.form.reset();
        },
        close() {
            this.$props.onClose();
        },
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid)
                return;
            if (this.editting) {
                this.$emit('moveNextStep', { ...this.form });
            }
            else {
                this.$emit('finish', { ...this.form });
            }
        },
        fillControls(product) {
            const formCopy = { ...this.form };
            for (let prop in formCopy) {
                if (prop === 'atributosProducto')
                    continue;
                formCopy[prop] = prop === 'foto' ? null : product?.[prop];
            }
            const atributos = product?.cmsgp.map((cmAtr) => cmAtr.CMAtributoSubgrupo?.id);
            const familyId = product?.CmSubGrupo.CmGrupo.id_familia;
            const groupId = product?.CmSubGrupo.CmGrupo.id;
            formCopy.atributosProducto = atributos;
            if (product) {
                delete formCopy.foto;
                this.fetchGroups(familyId);
                this.obtenerSubGrupo(groupId);
            }
            else {
                formCopy['foto'] = null;
            }
            this.extraFormFields.id_familia = familyId;
            this.extraFormFields.id_grupo = groupId;
            this.form = formCopy;
        },
        async buscarSubGrupo(termino) {
            if (this.filtroSubGrupo === termino) return;
            this.filtroSubGrupo = termino;
            this.subGrupo_list.data = [];
            this.paginacionSubGrupo.pagina = 1;
            this.obtenerSubGrupo(this.extraFormFields.id_grupo);
        },
        async cargarMasSubGrupo() {
            if (this.subGrupo_list.isLoading) return;

            const { page, per_page, total_rows } = this.subGrupo_list.pagination;
            const currentlyLoaded = page * per_page;
            if (!(currentlyLoaded < total_rows))
                return;
            this.paginacionSubGrupo.pagina = page + 1;
            this.paginacionSubGrupo.registrosPorPagina = per_page;
            this.obtenerSubGrupo(this.extraFormFields.id_grupo);
        },
        async obtenerSubGrupo(groupId) {
            const filtros = {
                pagination: true,
                per_page: this.paginacionSubGrupo.registrosPorPagina,
                page: this.paginacionSubGrupo.pagina,
                busqueda: this.filtroSubGrupo,
            };
            const itemsCopy = this.subGrupo_list.data;
            togglePageable(this.subGrupo_list);
            const { data, headers } = await this.services.SubGroup.getSubGroups({ id_grupo: groupId, ...filtros });
            setPageableResponse(this.subGrupo_list, data, headers, { skipOnSuccess: true, persistDataOnError: true });
            if (!isResponseSuccesful(data)) {
                // Hacemos rollback de la pagina en caso de error para volver a cargar la misma al scrollear
                const { pagina } = this.paginacionSubGrupo;
                this.paginacionSubGrupo.pagina = pagina > 1 ? pagina - 1 : 1;
                return;
            }
            this.subGrupo_list.data = filtros.page > 1 ? itemsCopy.concat(data.data) : data.data;

        },
    },
    watch: {
        product(value) {
            this.fillControls(value);
        },
        idSubGrupo(value) {
            if (value) {
                this.fetchSubProcesoAtributoProducto(value);
                return;
            }
            this.atributosProducto = createLoadable([]);
        },
    },
    mounted() {
        if (shouldFetch(this.families))
            this.fetchFamilies();
        if (shouldFetch(this.productTypes))
            this.fetchProductTypes();
        this.fillControls(this.$props.product);
    },
}
</script>